import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { KurzUnitService } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzUnitTranslate',
  pure: false
})
export class KurzUnitTranslationPipe implements PipeTransform, OnDestroy {

  private oldArgs = {
    unit: null,
    unitFactor: null,
    maximumFractionDigits: null,
    minimumFractionDigits: null
  };

  private sub: Subscription;

  private currentTranslation = '';

  constructor(private kurzUnitService: KurzUnitService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * @return string observable, to which needs to be subscribed by using the async pipe
   */
  // transform(unit: string, unitFactor?: number, maximumFractionDigits?: number, minimumFractionDigits?: number): Observable<string> {
  //   return this.kurzUnitService.getKurzUnitTranslation(unitFactor ?? 1, unit, maximumFractionDigits, minimumFractionDigits);
  // }

  /**
   * translates the kurz specific unit strings (M2, ROL, PFI, MSI, MMM, FTI' note: case insensitive in algorithm)
   * @param unit - string, which needs to be piped
   * @param unitFactor - number, first argument of the pipe and the unit factor of the translation, default: 1
   * @param maximumFractionDigits - number, second argument of the pipe and maximum allowed fraction digits of the number, default: 2
   * @param minimumFractionDigits - number, third argument of the pipe and minimum allowed fraction digits of the number, default: 0
   * @returns string
   */
  transform(unit: string, unitFactor?: number, maximumFractionDigits?: number, minimumFractionDigits?: number): string {
    if (this.isNew(unit, unitFactor, maximumFractionDigits, minimumFractionDigits)) {
      this.sub?.unsubscribe();
      this.sub = this.kurzUnitService.getKurzUnitTranslation(unit, unitFactor, maximumFractionDigits, minimumFractionDigits).subscribe(trans => {
        this.currentTranslation = trans;
        this.cdr.markForCheck();
      });
    }
    return this.currentTranslation;
  }

  /**
   * are there new args
   */
  private isNew(unit: string, unitFactor: number, maximumFractionDigits?: number, minimumFractionDigits?: number): boolean {

    const isNew = unit !== this.oldArgs.unit ||
                  unitFactor !== this.oldArgs.unitFactor ||
                  maximumFractionDigits !== this.oldArgs.maximumFractionDigits ||
                  minimumFractionDigits !== this.oldArgs.minimumFractionDigits
                  ;

    if (isNew) {
      this.oldArgs = {unit, unitFactor, maximumFractionDigits, minimumFractionDigits};
    }

    return isNew;

  }

}
